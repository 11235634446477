<template>
  <div>
    <b-card header="Add Session">
      <spinner-loader :loading="loading" />

      <session-form
        v-if="!loading"
        :submit="addSession"
        :event="event"
        :session.sync="session"
      />
    </b-card>
  </div>
</template>

<script>
import handleAlerts from '@/common/compositions/common/handleAlerts'
import SessionForm from '@/common/components/QuickEvents/Sessions/SessionForm.vue'
import SpinnerLoader from '@/common/components/common/Table/SpinnerLoader.vue'
import SessionApi from '@/common/compositions/QuickEvents/sessionApi'

export default {
  name: 'AddSession',
  components: {
    SessionForm,
    SpinnerLoader,
  },
  data() {
    return {
      event: {},
      loading: true,
    }
  },
  setup() {
    const { session, addSessionRequest } = SessionApi()
    const { successfulOperationAlert } = handleAlerts()
    return { session, addSessionRequest, successfulOperationAlert }
  },
  created() {
    this.getEvent()
  },
  methods: {
    addSession() {
      return this.addSessionRequest(this.$route.params.eventId).then(() => {
        this.successfulOperationAlert('Session is added successfully')
        this.$router.push({ name: 'quick-event-sessions-list' })
      })
    },
    getEvent() {
      return this.$activities.get(`internalops/events/${this.$route.params.eventId}`).then(res => {
        this.event = {
          ...res.data.data,
          location: { ...res.data.data.location },
          image: [res.data.data.image],
          brochure: [res.data.data.brochure],
        }

        this.setFirstSession(this.event)
      }).finally(() => {
        this.loading = false
      })
    },
    setFirstSession(event) {
      if (event.sessionsCount === 0) {
        this.$set(this.session, 'topic', event.name)
        this.$set(this.session, 'start_time', event.startTime)
        this.$set(this.session, 'end_time', event.endTime)
        this.$set(this.session, 'general_notes', event.generalNotes || '')
      }
    },
  },
}
</script>

<style lang="scss" scoped>
</style>
